/**
 * 侧边栏菜单
 * 权限直接下发id给前端来控制
 * 路由定义依然在 router/index.js 里, 只重构了侧边栏菜单, 路由因为涉及历史的一些跳转, 并未去改动路由表
 * icon 用的 iconfont 上的 wk-pc: https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=3818554
 */

export default [{
  id: -1,
  label: '快递打单',
  icon: 'menu-ups',
  children: [
    {
      id: 0,
      label: '工作台',
      icon: 'menu-workbench',
      path: '/home'
    },
    {
      id: 101,
      label: '试算价格',
      icon: 'menu-order',
      path: '/order/neworder-product-list'
    },
    {
      id: 1,
      label: '订单管理',
      icon: 'menu-order',
      children: [
        {
          id: 105,
          label: '新建订单(批量)',
          path: '/order/neworder-batch'
        },
        {
          id: 102,
          label: '订单管理',
          path: '/order/ordermanagement'
        },
        {
          id: 103,
          label: '问题件管理',
          path: '/order/wrongorder'
        },
        {
          id: 130,
          label: '订单工单',
          path: '/order/workorder'
        },
        {
          id: 104,
          label: '订单费用异常',
          path: '/abnormalcharge'
        },
        {
          id: 106,
          label: '订单取消审批',
          path: '/order/cancel'
        },
        {
          id: 127,
          label: '订单供应商对照',
          path: '/order/supplier-order'
        },
      ]
    },
    {
      id: 4,
      label: '交易管理',
      icon: 'menu-trade',
      children: [
        {
          id: 401,
          label: '交易流水(新)',
          path: '/cost/bank-slip'
        },
        {
          id: 401,
          label: '对账看板',
          path: '/cost/bill-board'
        },
        {
          id: 401,
          label: '交易流水',
          path: '/cost/costtrial'
        },
        {
          id: 402,
          label: '客户充值',
          path: '/cost/deposit'
        },
        {
          id: 403,
          label: '充值审核',
          path: '/cost/depositaudit'
        },
        {
          id: 407,
          label: '应收账单列表',
          path: '/cost/querybill'
        },
        {
          id: 406,
          label: '应付账单列表',
          path: '/cost/supplierbill'
        },
        {
          id: 405,
          label: '供应商付款记录',
          path: '/cost/supplierbalancemovement'
        },
      ]
    },
    {
      id: 5,
      label: '费用管理',
      icon: 'menu-fee',
      children: [
        {
          id: 501,
          label: '订单汇总',
          path: '/ordercharge',
        },
        {
          id: 502,
          label: '订单费用导入',
          path: '/chargeimport',
        },
        {
          id: 505,
          label: '订单费用转换',
          path: '/transferChargeImport',
        },
        {
          id: 506,
          label: '供应商订单账单',
          path: '/supplierOrderBill',
        },
        {
          id: 507,
          label: '供应商订单账单明细',
          path: '/supplierOrderBillDetail',
        },
        {
          id: 508,
          label: '供应商账单导入',
          path: '/hyOrderBillImport',
        },
        {
          id: 510,
          label: '对账管理',
          path: '/cost/reconciliation-management',
        },
        {
          id: 511,
          label: '订单费用管理',
          path: '/cost/createbill',
        },
        {
          id: 512,
          label: '客户自有渠道订单费用',
          path: '/cost/self-channel',
        },
      ]
    },
    {
      id: 6,
      label: '渠道设置',
      icon: 'menu-channel',
      children: [
        {
          id: 601,
          label: '平台渠道管理',
          path: '/channelsettings',
        },
        {
          id: 602,
          label: '客户自有渠道管理',
          path: '/channelsettings-self'
        }
      ]
    },
    {
      id: 9,
      label: '供应商系统管理',
      icon: 'menu-supplier-system',
      path: '/supplier-system'
    },
    {
      id: 8,
      label: '报表中心',
      icon: 'menu-pin',
      children: [
        {
          id: 801,
          label: '按客户统计信息',
          path: '/report/customer',
        },
        {
          id: 802,
          label: '按渠道统计信息',
          path: '/report/channel',
        },
        {
          id: 803,
          label: '运营监控报表',
          path: '/report/report2',
        },
      ]
    },
  ]
}, {
  id: 20,
  label: '海外仓(提拆派)',
  icon: 'menu-warehouse',
  children: [
    {
      id: 2004,
      label: '订单管理',
      icon: 'menu-order',
      path: '/warehouse/order'
    },
    {
      id: 2005,
      label: '供应商管理',
      icon: 'menu-supplier',
      path: '/warehouse/supplier'
    },
    {
      id: 2006,
      label: '平台报价管理',
      icon: 'menu-quote',
      path: '/warehouse/quote'
    },
  ]
}, {
  id: 3,
  label: '客户管理',
  icon: 'menu-customer',
  children: [
    {
      id: 301,
      label: '客户管理',
      path: '/usermanagement'
    },
    {
      id: 302,
      label: '客户企业信息管理',
      path: '/customer/company-management'
    },
  ]
}, {
  id: 7,
  label: '系统设置',
  icon: 'menu-setting',
  children: [
    {
      id: 701,
      label: '地址管理',
      path: '/address/base'
    },
    {
      id: 702,
      label: 'FBA地址管理',
      path: '/address/fba'
    },
    {
      id: 703,
      label: '密码修改',
      path: '/password/edit'
    },
    {
      id: 704,
      label: '供应商管理',
      path: '/system/suppliermanagement'
    },
    {
      id: 706,
      label: '内部员工',
      path: '/user/staff'
    },
    {
      id: 707,
      label: '权限分配',
      path: '/user/jurisdiction'
    },
    {
      id: 708,
      label: '供应商渠道对比工具',
      path: '/tool/compare'
    },
    {
      id: 709,
      label: '公告配置',
      path: '/system/notice'
    },
    {
      id: 710,
      label: '汇率维护',
      path: '/system/exchange-rate'
    },
  ]
}, {
  id: 20001,
  label: 'CRM数据看板',
  icon: 'menu-crm',
  path: '/crm-data-board'
}]
