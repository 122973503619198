const isProd = process.env.NODE_ENV === 'production'
export const isOnline = ['admin.wukongship.com'].includes(location.hostname)
export const isSpareDomain = ['admin.52alliances.com'].includes(location.hostname) // 备用域名

let apiBaseURL = '/wkapi/'
let env = 'pro' // 环境：pro-生产  dev-开发  test-测试
const mockApi = 'https://rap2.17haoyun.cn/app/mock/94/'  // 接口文档 mock 地址

// 根据域名配置接口
switch (location.hostname.split('.')[0]) {
  case '192':
  case '127':
  case 'localhost': // 本地环境
  case 'dev-admin': // 开发环境
    env = 'dev'
    // apiBaseURL='http://192.168.2.229:9905/' // 匡衡本机
    // apiBaseURL= 'http://192.168.0.178:9905/' // 乐天本机
    // apiBaseURL= 'http://192.168.0.196:9905/' // 虚竹本机
    // apiBaseURL= 'http://192.168.1.16:9905/' // 和风本机
    // apiBaseURL= 'https://test-admin.wukongship.com/wkapi/'
    break
  case 'test-admin': // 测试环境
    env = 'test'
    break
}

export default {
  isProd,
  isOnline,
  isSpareDomain,
  apiBaseURL,
  env,
  mockApi
}
