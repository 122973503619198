<template>
  <el-container class="g-layout">
    <el-aside width="240px" class="g-layout-aside">
      <div>
        <router-link to="/">
          <img v-if="vipLogo" class="g-layout-logo" :src="vipLogo">
          <iconsvg v-else name="logo" />
        </router-link>
        <el-menu
          router
          unique-opened
          background-color="#333752"
          text-color="#B5B7BD"
          :default-active="$route.path"
        >
          <VSubmenu v-for="(item, index) in menus" :key="index" :item="item" />
        </el-menu>
      </div>
      <p class="copyright">©{{ new Date().getFullYear() }} 悟空尾派 版权所有</p>
    </el-aside>
    <el-container>
      <el-header height="60px" class="g-layout-header">
        <VHeader
          ref="VHeader"
          :profile="profile"
          :balance="balance"
          :admin="admin"
          :channel-info="channelInfo"
          :vip-logo="vipLogo"
        />
      </el-header>
      <el-main class="g-layout-main">
        <router-view/>

        <!-- 这个是之前的,没看懂是要做啥的,暂时保留 -->
        <input type="hidden" id="isAdmin" :value="admin" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import wj from '@/util/wjtools'
import VHeader from './v-header'
import VSubmenu from './v-submenu'

export default {
  components: {
    VHeader,
    VSubmenu
  },
  data() {
    return {
      channelInfo: [],
      vipLogo: null,
      profile: '',
      balance: 0,
      admin: false,
    }
  },
  computed: {
    ...mapState('app', ['menuInfo', 'permission']),

    menus() {
      const menuInfo = this.menuInfo || {}
      return menuInfo.menus || []
    }
  },

  created() {
    this.getPermission()
    this.getUserInfo()
  },

  methods: {
    ...mapActions('app', ['getPermission']),

    async getUserInfo() {
      const res = await this.$axios({
        method: 'get',
        url: 'user-info',
        transformRes: true,
      }).catch(err => console.log(err))

      const data = res.data || {}
      const username = data.username
      if(res.success && username) {
        wj.store('userInfo', data)
        if (data.userId === '4ba1912a-6b0d-4947-b974-308d795fdf5b') {
          this.vipLogo = 'https://kjdms.oss-cn-shenzhen.aliyuncs.com/images/JLSS_Logo.png'
        }
        if(data.admin == false && data.needAlarm){
          this.$alert('当前可用余额不足500美元，为保证正常下单，请联系平台客服进行充值。', {
            type:'warning',
            showClose: false,
            callback: () => {
              this.readAlarm()
            }
          })
        }
        this.profile = username
        this.admin = data.admin
        this.balance = data.balance
        this.channelInfo = data.channelInfo || []

        if (!data.menus) {
          this.$router.push('/nopermission')
        }
      } else if (!res.success){
        this.$router.push('/login')
      } else {
        this.$message.error(res.message || '获取用户名和菜单失败！')
      }
    },

    // 已读余额预警
    readAlarm(){
      this.$axios({
        method: 'get',
        url: 'alarm/msg/update',
      }).catch(err => console.log(err))
    }
  }
}
</script>

<style lang="scss" scoped>
.g-layout {
  height: 100vh;
  background-color: #f1f3f5;
  .g-layout-aside {
    height: 100%;
    padding: 0 8px;
    background: #333752;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include scrollbar();
    .g-layout-logo {
      margin: 14px 16px;
    }
    .icon-logo {
      width: 118px;
      height: 35px;
      color: #FFF;
      margin: 14px 16px;
    }
    ::v-deep .el-menu {
      border: none;
      .el-icon-arrow-down:before {
        content: $triangle-arrow-down;
      }
      .el-menu-item.is-active {
        background: #1e5eff !important;
        border-radius: 4px;
        font-weight: bold;
        color: #FFF;
      }
      .iconsvg {
        font-size: 20px;
        vertical-align: middle;
      }
    }
    .copyright {
      font-size: 12px;
      color: #eaecf5;
      line-height: 18px;
      width: 100%;
      text-align: center;
      margin: 30px 0;
    }
  }
  .g-layout-header {
    background: #FFF;
    border: none;
  }
  .g-layout-main {
    padding: 0px;
    @include scrollbar();
  }
}
</style>
