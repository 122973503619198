import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import starpostUI from 'starpost-ui'
import axios from '@/util/ajax'
import config from './config'
import '@/assets/scss/public.scss'
import '@/styles/element-var.scss'
import '@/styles/main.scss'
import istat from './util/istat'
import filters from './filters'

// 全局注册常用组件
import iconsvg from '@/components/iconsvg.vue'
import VDialog from '@/components/v-dialog.vue'
import VPager from '@/components/v-pager.vue'

// 全局指令
import directives from '@/directives'

filters(Vue) // 注册全局过滤器

Vue.prototype.$config = config
Vue.config.productionTip = false
Vue.prototype.$axios = axios

// 初始化日志上报系统
Vue.use(istat, {
  url: 'https://log.kjhaoyun.com/wk.gif',
  // debug: !config.isOnline,
  debug: true,
})

Vue.use(directives)
Vue.use(ElementUI)
Vue.use(starpostUI)
Vue.component('iconsvg', iconsvg)
Vue.component('VDialog', VDialog)
Vue.component('VPager', VPager)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app")
