/**
 * 主页面子路由表
 * 全部放到一起维护，方便后续侧边栏菜单的任意调整，按照侧边栏菜单顺序对应
 */
const routes = [
  { path: '/home', name: '工作台', component: () => import('@/views/workbench/index') },
  { path: '/order/neworder-product-list', name: '试算价格', component: () => import('@/views/order/new-order') },
  // 订单
  { path: '/order/neworder-batch', name: '新建订单(批量)', component: () => import('@/views/order/neworder_batch') },
  { path: '/order/ordermanagement', name: '订单管理', component: () => import('@/views/order/ordermanagement') },
  { path: '/order/wrongorder', name: '问题件管理', component: () => import('@/views/order/wrongorder') },
  { path: '/order/workorder', name: '订单工单', component: () => import('@/views/order/workorder') },
  { path: '/abnormalcharge', name: '订单费用异常', component: () => import('@/views/orderOperation/abnormalcharge') },
  { path: '/order/cancel', name: '订单取消审批', component: () => import('@/views/order/order_cancel') },
  { path: '/order/supplier-order', name: '订单供应商对照', component: () => import('@/views/order/supplier_order') },
  // 交易管理
  { path: '/cost/costtrial', name: '交易流水', component: () => import('@/views/cost/costtrial') },
  { path: '/cost/bank-slip', name: '交易流水(新)', component: () => import('@/views/cost/bank-slip') },
  { path: '/cost/bill-board', name: '对账看板', component: () => import('@/views/cost/bill-board/bill-board') },
  { path: '/cost/deposit', name: '用户充值', component: () => import('@/views/cost/deposit') },
  { path: '/cost/depositaudit', name: '充值审核', component: () => import('@/views/cost/depositaudit') },
  { path: '/cost/querybill', name: '应收账单列表', component: () => import('@/views/cost/billquery') },
  { path: '/cost/supplierbill', name: '应付账单列表', component: () => import('@/views/cost/supplierbillquery') },
  { path: '/cost/supplierbalancemovement', name: '供应商付款记录', component: () => import('@/views/cost/supplierbalancemovement') },
  // 费用
  { path: '/ordercharge', name: '订单汇总', component: () => import('@/views/orderOperation/ordercharge') },
  { path: '/transferChargeImport', name: '订单费用转换', component: () => import('@/views/orderOperation/transferChargeImport') },
  { path: '/supplierOrderBill', name: '供应商订单账单', component: () => import('@/views/supplierbill/supplierorderbill') },
  { path: '/supplierOrderBillDetail', name: '供应商订单账单明细', component: () => import('@/views/supplierbill/supplierorderBillDetail') },
  { path: '/hyOrderBillImport', name: '环洋订单账单导入', component: () => import('@/views/supplierbill/HyOrderBillImport') },
  { path: '/cost/reconciliation-management', name: '对账管理', component: () => import('@/views/cost/reconciliation-management') },
  { path: '/cost/createbill', name: '订单费用管理', component: () => import('@/views/cost/billcreate') },
  { path: '/cost/self-channel', name: '客户自有渠道订单费用', component: () => import('@/views/cost/self-channel') },
  // 渠道设置
  { path: '/channelsettings', name: '平台渠道管理', component: () => import('@/views/system/channel') },
  { path: '/channelsettings-self', name: '客户自有渠道管理', component: () => import('@/views/system/channel/self-channel/self-channel') },
  // 供应商系统管理
  { path: '/supplier-system', name: '供应商系统管理', component: () => import('@/views/system/supplier-system/supplier-system') },
  // 报表中心
  { path: '/report/customer', name: '按客户统计信息', component: () => import('@/views/report/customer') },
  { path: '/report/channel', name: '按渠道统计信息', component: () => import('@/views/report/channel') },
  { path: '/report/report2', name: '运营监控报表', component: () => import('@/views/report/report2') },
  // 海外仓(提拆派)
  { path: '/warehouse/order', name: '海外仓-订单管理', component: () => import('@/views/warehouse/order/order') },
  { path: '/warehouse/supplier', name: '海外仓-供应商管理', component: () => import('@/views/warehouse/supplier/supplier') },
  { path: '/warehouse/quote', name: '海外仓-平台报价管理', component: () => import('@/views/warehouse/quote/quote') },
  // 客户管理
  { path: '/usermanagement', name: '客户管理', component: () => import('@/views/user/customer/usermanagement') },
  { path: '/customer/company-management', name: '客户企业信息管理', component: () => import('@/views/user/customer/company-management') },
  // 系统设置
  { path: '/address/base', name: '地址管理', component: () => import('@/views/address/base') },
  { path: '/address/fba', name: 'FBA地址管理', component: () => import('@/views/address/fba') },
  { path: '/password/edit', name: '密码修改', component: () => import('@/views/system/passwordEdit') },
  { path: '/system/suppliermanagement', name: '供应商管理', component: () => import('@/views/system/supplier') },
  { path: '/user/staff', name: '内部员工', component: () => import('@/views/user/staff') },
  { path: '/user/jurisdiction', name: '权限分配', component: () => import('@/views/user/jurisdiction.vue') },
  { path: '/tool/compare',name: '供应商渠道对比工具',component: () => import('@/views/system/comparetool') },
  { path: '/system/notice', name: '公告配置', component: () => import('@/views/system/notice') },
  { path: '/system/exchange-rate', name: '汇率维护', component: () => import('@/views/system/exchange-rate') },
  { path: '/export-records', name: '导出记录', component: () => import('@/views/orderOperation/export-records') },
  // CRM数据看板
  { path: '/crm-data-board', name: 'CRM数据看板', component: () => import('@/views/crm-data-board/crm-data-board') }
]

export default routes
